.personalFormContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0;
    .formTitle {
      position: relative;
      color: var(--text-color);
      font-weight: bold;
      font-size: 16px;
      // padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .titleBorder {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 10px;
  }
}
