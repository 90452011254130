.cardBoxSty {
  height: 0;
  justify-content: center;
  .monitorLevelList {
    display: flex;
    gap: 10px;
    padding-bottom: 8px;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
    height: 76px;
    .monitorItem {
      width: 64px;
      height: 68px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(255, 255, 255, 0.85);
      line-height: 18px;
      .monitorNum {
        font-size: 21px;
        line-height: 27px;
        font-family: YouSheBiaoTiHei;
      }
    }
    .monitorHighest {
      background-image: url(../../imgs/monitor_highest.png);
      .monitorNum {
        color: #ff4d4f;
      }
    }
    .monitorHigh {
      background-image: url(../../imgs/monitor_high.png);
      .monitorNum {
        color: #ff9247;
      }
    }
    .monitorMiddle {
      background-image: url(../../imgs/monitor_middle.png);
      .monitorNum {
        color: #00adff;
      }
    }
    .monitorLow {
      background-image: url(../../imgs/monitor_low.png);
      .monitorNum {
        color: #59d744;
      }
    }
    .monitorLowest {
      background-image: url(../../imgs/monitor_lowest.png);
      .monitorNum {
        color: #abd335;
      }
    }
  }
  .alarmList {
    height: 0;
    flex: 1;
    :global {
      .slick-list {
        height: 100% !important;
      }
    }
  }
  .alarmItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    gap: 8px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 20px;
    border-bottom: 1px solid #303030;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    .overflowSty {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
