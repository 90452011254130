.stationInfo {
  width: 163px;
  padding: 8px 12px;
  background: rgba(7, 34, 65, 0.9);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  position: relative;
  gap: 4px;
  z-index: 3;
  > div:not(.dropdownSwitch) {
    display: flex;
    justify-content: space-between;
  }
  &:has(.dropdownSwitch) {
    padding-bottom: 20px;
  }
}
.dropdownSwitch {
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 12px;
  width: 100%;
  text-align: center;
  background: #3570bd;
  border-radius: 0px 0px 4px 4px;
  cursor: pointer;
}

.dropdownContent {
  width: 163px;
  :global {
    .ant-tooltip-inner {
      display: flex;
      flex-direction: column;
      padding: 7px 12px !important;
      background: rgba(0, 0, 0, 0.8) !important;
      gap: 4px;
      > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
