.planContent {
  width: 100%;
  height: calc(100vh - 188px);
  border-top: 1px solid var(--mx-light-border-color);
  display: flex;

  .divider {
    height: 100%;
    width: 1px;
    background: var(--mx-light-border-color);
    margin-right: 9px;
  }
  .rightStyle {
    width: calc(100% - 270px);
    height: 100%;
    padding: 15px 20px 0 0;
    overflow: auto;
  }
}

.editPlanWrapper {
  padding: 20px 0 0 0;
}
.timeList {
  padding: 16px 20px;
}
.timeItem {
  width: 260px;
  height: 40px;
  background: #2d2d2d;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  color: var(--mx-text-base-color);
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    background: #2d2d2d;
    border: 1px solid #4a90e2;
  }
}

.timeItem.active {
  background: rgba(74, 144, 226, 0.1);
  border: 1px solid #4a90e2;
}
