.outer_wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: #0d0f19;
  position: relative;

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    // pointer-events: none; /* 允许交互事件 */
    .inner_top {
      height: 110px;
      width: 100%;
      // pointer-events: auto;
    }
    .inner_center {
      height: 0px;
      flex: 1;
      display: flex;
      .center_center_box {
        height: 100%;
        flex: 1;
        position: relative;
      }
      .backBtn {
        position: absolute;
        top: 16px;
        left: 24px;
        font-family: YouSheBiaoTiHei;
        font-size: 24px;
        color: #ffffff;
        line-height: 56px;
        width: 160px;
        text-align: center;
        cursor: pointer;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        background-image: url(./imgs/button-back@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .center_right_box {
        height: 100%;
        width: 400px;
        padding: 0 16px;
        background: #0d0f19;
        .right_wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .content_wrapper {
            flex: 1;
            height: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            border-top: transparent;
            border-radius: 0px 0px 4px 4px;
            opacity: 0.9;
            position: relative;
            .content_top_box {
              height: 0px;
              flex: 5;
              width: 100%;
              display: flex;
              flex-direction: column;
            }
            .content_center_box {
              height: 0px;
              flex: 4;
              width: 100%;
              display: flex;
              flex-direction: column;
            }
            .content_bottom_box {
              height: 0px;
              flex: 6;
              width: 100%;
              display: flex;
              flex-direction: column;
            }
            .light_line {
              bottom: 0;
              width: 100%;
              height: 2px;
            }
          }
        }
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0); /* 初始位置 */
  }
  50% {
    transform: translateY(-15px); /* 上升位置 */
  }
  100% {
    transform: translateY(0); /* 回到初始位置 */
  }
}

@keyframes scale {
  0% {
    transform: scale(1.5); /* 初始大小 */
  }
  50% {
    transform: scale(1); /* 放大到1.5倍 */
  }
  100% {
    transform: scale(1.5); /* 缩小回原始大小 */
  }
}
