.stationArea {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  .stationTab {
    margin: 16px 0 0 16px;
    display: flex;
    gap: 16px;
    .stationItem {
      cursor: pointer;
    }
  }
  .stationList {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .stationItem {
    width: fit-content;
    padding: 0 16px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    border: 2px solid;
    position: relative;
    z-index: 3;
    cursor: default;
    .errorIcon {
      position: absolute;
      width: 24px;
      height: 24px;
      left: -12px;
      bottom: 5px;
    }
    .errorItem {
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: rgba(230, 66, 66, 0.4);
      border-radius: 50%;
      bottom: 3px;
      left: -12px;
      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        opacity: 0.7;
        background-color: rgba(230, 66, 66, 0.6);
        animation: scaless 1s infinite cubic-bezier(0, 0, 0.49, 1.02);
      }
    }

    @keyframes scaless {
      0% {
        transform: scale(1);
      }

      75% {
        transform: scale(2);
      }

      100% {
        opacity: 0;
      }
    }
  }
  .gasStation:not(.stationDisabled) {
    background: rgba(108, 60, 9, 0.8);
    border-color: rgba(255, 224, 129, 1);
  }
  .electricityStation:not(.stationDisabled) {
    background: rgba(14, 55, 62, 0.8);
    border-color: #52e7ff;
  }
  .waterPumpStation:not(.stationDisabled) {
    background: rgba(47, 32, 66, 0.8);
    border-color: #b57cfc;
  }
  .stationDisabled {
    background: rgba(151, 151, 151, 0.8);
    border-color: #b3b3b3;
    color: rgba(255, 255, 255, 0.45);
  }
}
