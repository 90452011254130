.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
}

.charts_wrapper {
  flex: 1;
  width: 100%;
  height: 0px;
}
