.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
  justify-content: center;
  .tableHeader,
  .tableRow {
    display: flex !important;
    align-items: center;
    position: relative;
    gap: 8px;
    height: 40px;
  }
  .tableHeader {
    margin-bottom: 6px;
    background: linear-gradient(
      90deg,
      rgba(55, 156, 236, 0) 0%,
      rgba(41, 129, 224, 0.29) 47%,
      rgba(25, 98, 211, 0) 100%
    );
    :first-child {
      padding-left: 16px;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(90deg, rgba(79, 0, 255, 0) 0%, #02c1ff 50%, rgba(79, 0, 255, 0) 100%);
    }
  }
  .tableBody {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    .tableRow {
      margin-bottom: 2px;
      :first-child {
        padding-left: 20px;
      }
      &::after {
        content: '';
        display: block;
        width: 120px;
        height: 2px;
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 128, 37, 0) 0%,
          rgba(255, 128, 37, 0.8) 48%,
          rgba(255, 128, 37, 0) 100%
        );
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    :global {
      .slick-list {
        height: 100% !important;
      }
    }
  }
}
