.highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 覆盖图片的宽度 */
  height: 100%; /* 覆盖图片的高度 */
  // pointer-events: none;
  z-index: 1;

  .region {
    fill: transparent;
    stroke: none;
    // pointer-events: auto; /* 允许鼠标事件 */
    transition: fill 0.3s ease;
    z-index: 2;
  }

  /* 鼠标悬浮高亮样式 */
  .region:hover {
    fill: url(#gradient); /* 悬浮时应用渐变 */
    z-index: 3;
    cursor: pointer;
  }
}
