@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('../../../../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
}

.header_area_box {
  display: flex;
  padding: 0 20px;
  background-image: url(../../imgs/mainTittleLine.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title_box {
    width: 50%;
    line-height: 110px;
    font-size: 48px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    padding-left: 110px;
  }

  .tab_box {
    display: flex;
    width: 30%;
    padding: 22px 0 32px;
    .tab_item {
      width: 180px;
      height: 56px;
      background-image: url(../../imgs/button-bg-default@2x.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-family: YouSheBiaoTiHei;
      font-size: 26px;
      color: #ffffff;
      line-height: 56px;
      text-align: center;
      cursor: pointer;
      &:last-child {
        width: 80px;
        background-image: url(../../imgs/button-bg-default-more@2x.png);
        cursor: default;
      }
      &.tab_active {
        background-image: url(../../imgs/button-bg-selected@2x.png);
      }
    }
  }

  .set_box {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    align-items: center;
    height: 24px;
    gap: 6px;
    flex: 1;
    width: 0px;
    .refreshTimeSty {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 20px;
      margin-right: 8px;
    }
  }

  .btn {
    cursor: pointer;
  }
}
