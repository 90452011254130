.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
}

.tabsStyle {
  height: 20px;
  width: 100%;
  :global {
    .ant-tabs-nav {
      margin: 0px;
    }

    .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-nav-list,
    .ant-tabs-nav-operations {
      height: 24px;
    }
    .ant-tabs-tab {
      font-size: 11px;
      font-weight: 400;
      color: #94bdec;
      line-height: 15px;
      padding: 3px 12px;
      border-radius: 2px;
      border: 1px solid #295281;
      height: 24px;
      &:hover {
        background: rgba(74, 144, 226, 0.15);
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 6px !important;
    }
    .ant-tabs-tab-active {
      height: 24px;
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;
      .ant-tabs-tab-btn {
        color: #fff !important;
      }
      &:hover {
        background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      }
    }
    .ant-tabs-ink-bar {
      height: 1px;
      background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
    }
    .ant-tabs-nav-operations {
      margin-left: 6px;
      border-radius: 2px;
      border: 1px solid #295281;
    }
    .ant-tabs-nav-more {
      padding: 0px 8px !important;
    }
  }
}

.charts_wrapper {
  flex: 1;
  width: 100%;
  height: 0px;
}
